import {
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule, 
    MatExpansionModule,
      MatRadioModule, 
       MatTableModule, 
       MatButtonModule, 
       MatProgressBarModule,
        MatTabsModule, 
        MatSliderModule, 
        MatSlideToggleModule
    
  } from "@angular/material";
import { NgModule } from '@angular/core';

@NgModule({
    imports: [
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatMenuModule,
        MatExpansionModule,
        MatRadioModule,
        MatTableModule, 
        MatButtonModule, 
        MatProgressBarModule,
        MatTabsModule, 
        MatSliderModule, 
        MatSlideToggleModule
    ],
    exports: [
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatMenuModule,
        MatExpansionModule,
        MatRadioModule,
        MatTableModule, 
        MatButtonModule, 
        MatProgressBarModule,
        MatTabsModule, 
        MatSliderModule, 
        MatSlideToggleModule
    ],
})
export class MaterialModule {}