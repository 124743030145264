export class PropertyTax {
    _id?: string
    buildingForm: string;
    premiseForm: string;
    sessionDashboard?: any;
    sessionDataView?: any;
    buildingBaseLayer?: string;
    buildingBaseLayerProperty?: string;
    project?: string

    constructor(id?, buildingForm?, premiseForm?, sessionDashboard?, sessionDataView?, buildingBaseLayer?, buildingBaseLayerProperty?, project?) {
        this._id = id || "";
        this.buildingForm = buildingForm || "";
        this.premiseForm = premiseForm || "";
        this.sessionDashboard = sessionDashboard || "";
        this.sessionDataView = sessionDataView || "";
        this.buildingBaseLayer = buildingBaseLayer || "";
        this.buildingBaseLayerProperty = buildingBaseLayerProperty || "";
        this.project = project || ""
    }

    // constructor() {
    //     this._id = "";
    //     this.buildingForm = "";
    //     this.premiseForm = "";
    //     this.buildingBaseLayer = "";
    //     this.buildingBaseLayerProperty = ""
    // }


}

// let propertyTaxObject: PropertyTax = new PropertyTax();
